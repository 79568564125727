@media only screen and (min-height: 1200px){
	.bx-viewport{
		height: 990px !important;
	}
	#home1 .bxslider .slider-asian img {
		content: url(../images/banner-asian-games-1600.jpg);
	    top: -24px;
	    height: 100%;
	}
	#home1 .bxslider .slide-obh img {
		content: url(../images/bg-home01-1600.jpg);
		height: 100%;
		top: 0;
	}
	#home1 .bx-wrapper .bx-pager, #home1 .bx-wrapper .bx-controls-auto{
		bottom: 115px;
	}
}

// @media only screen and (min-height: 1024px){
// 	#home1 .bxslider .slider-asian img{
// 		position: relative;
// 		top: 70px;
// 	}
// 	#home1 .bxslider li img {
// 		height: 480px;
// 	}
// 	#home1 .bxslider .slide-obh img{
// 		top: 70px;
// 	}
// }


@media only screen and (max-width: 960px){
	#home1 .bx-wrapper img{
		height: 600px;
	}
	#home1 .bx-wrapper .bx-pager, #home1 .bx-wrapper .bx-controls-auto{
		bottom: 75px;
	}
	#home1 .bxslider .slide-obh .wrapper{
		top: 45%;
	}
	#home1 .wrapper .text-wrapper .right-text{
		transform: scale(0.8,0.8);
	}
}

@media only screen and (max-width: 854px) {
	#home1 .bxslider .slider-donasi img {
	    object-position: center;
	}
}

@media only screen and (max-width: 768px){
	#home1 .wrapper .text-wrapper .right-text{
		transform: none;
	}
	#home1 .bxslider .slide-obh .wrapper{
		top: 56%;
	}
	#home1 .bx-wrapper .bx-pager, #home1 .bx-wrapper .bx-controls-auto{
		bottom: 37px;
	}
}
	
@media only screen and (max-width: 480px) {
	#home1 .bxslider .slider-donasi img {
	    content: url(../images/mobile-bannerdonasi.jpg);
	    top: 22px;
	    position: relative;
	    height: 637px;
	}
}

@media only screen and (max-width: 375px) {
	#home1 .bxslider .slider-donasi img {
		content: url(../images/mobile-bannerdonasi-375.jpg);
	    top: 140px;
	    position: relative;
	    height: 562px;
	}
}

@media only screen and (max-width: 320px) {
	#home1 .bxslider .slider-donasi img {
		content: url(../images/mobile-bannerdonasi-320.jpg);
	    height: 517px;
	}
}



